import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { getCurrentUser, getAdmins } from "@/composables/firebase";
import { useUserStore } from "@/stores/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/notallowed",
    name: "notallowed",
    component: () => import("../views/NotAllowed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/approved",
    name: "approved",
    component: () => import("../views/ApprovedLocations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:id",
    name: "location",
    component: () => import("../views/LocationView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("../views/FeedbackView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await getCurrentUser())) {
    next("login");
  } else {
    if (to.name === "home" || to.name === "feedback") {
      const admins = await getAdmins();
      if (admins.includes(userStore.user.uid)) {
        next();
      } else {
        next("notallowed");
      }
    } else {
      next();
    }
  }
});

export default router;
