import { defineStore } from "pinia";
import { ref } from "vue";

export const useLocationsStore = defineStore("locationsStore", () => {
  const locations = ref<any>([]);
  const openedLocations = ref<any>(new Object());

  const notApprovedStop = ref<boolean>(false);
  const approvedStop = ref<boolean>(false);
  const approvedLastDocSnapshot = ref<any>(null);
  const notApprovedLastDocSnapshot = ref<any>(null);
  const notQuery = ref<boolean>(false);
  const query = ref<boolean>(false);

  return {
    locations,
    openedLocations,
    notApprovedStop,
    approvedStop,
    approvedLastDocSnapshot,
    notApprovedLastDocSnapshot,
    query,
    notQuery,
  };
});
