import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { auth } from "@/composables/firebase";
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";

export const useUserStore = defineStore("userStore", () => {
  const user = ref<any>(undefined);
  const router = useRouter();

  function clear() {
    user.value = undefined;
  }

  function logout() {
    signOut(auth)
      .then(() => {
        clear();
        router.push("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function googleLogin() {
    const provider = new GoogleAuthProvider();
    loginWithPopup(provider);
  }

  function loginWithPopup(provider: any) {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const loggedUser = result.user;
        user.value = loggedUser;
        router.push("/");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }

  return {
    user,
    clear,
    googleLogin,
    logout,
  };
});
